/**
 * Functions for mentor's inline SVG faces.
 */

import React from 'react';
import "./MentorFace.css"

export function MentorTalk() {
    return(
        <svg className="mentor-face" width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="41" cy="41" r="41" fill="var(--pal-cream)"/>
            <circle cx="23.5" cy="30.5" r="9.5" fill="var(--pal-main)"/>
            <circle cx="57" cy="31" r="10" fill="var(--pal-main)"/>
            <path d="M23.9343 55.4438C23.5759 55.3535 23.1969 55.4683 22.9488 55.7422C22.7007 56.0162 22.6239 56.4047 22.7492 56.7524C23.7348 59.4882 27.5457 65.2405 35.115 67.2686C42.692 69.2989 49.5104 66.092 52.0255 64.2011C52.3335 63.9696 52.481 63.5818 52.4049 63.2042C52.3288 62.8265 52.0425 62.5262 51.6689 62.4321L23.9343 55.4438Z" fill="var(--pal-main)" stroke="var(--pal-main)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export function MentorNeutral() {
    return(
        <svg className="mentor-face"  width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="41" cy="41" r="41" fill="var(--pal-cream)"/>
            <circle cx="23.5" cy="30.5" r="9.5" fill="var(--pal-main)"/>
            <circle cx="57" cy="31" r="10" fill="var(--pal-main)"/>
            <path d="M39.9975 64.5C40.4358 64.5022 40.874 64.4927 41.3101 64.4732L41.3121 64.4731C44.7874 64.3311 47.7532 63.6511 49.9247 63.0222C50.9561 62.7395 51.8084 62.4697 52.4053 62.2697C52.7045 62.1694 52.9398 62.0866 53.1017 62.0283C53.1826 61.9992 53.2452 61.9762 53.2883 61.9602L53.3381 61.9416L53.3517 61.9364L53.3557 61.9349L53.3569 61.9345L53.3574 61.9343L53.3576 61.9342C53.3576 61.9342 53.3577 61.9342 53.0009 61L53.3577 61.9342C53.857 61.7434 54.1181 61.1937 53.9503 60.6862C53.7826 60.1787 53.2455 59.8928 52.7308 60.0372C52.4473 60.1167 52.0705 60.2419 51.642 60.3843C51.5519 60.4143 51.4594 60.445 51.3651 60.4762C50.8086 60.6603 50.1449 60.8759 49.3821 61.0971C47.2053 61.6931 44.2281 62.3402 41.2266 62.4749C40.8254 62.4913 40.4172 62.5 40.0025 62.5C36.2822 62.4809 32.6214 61.5696 30.4135 60.8787C29.9581 60.7199 29.5639 60.5718 29.2255 60.4443C29.2121 60.4392 29.1987 60.4342 29.1853 60.4292C28.8703 60.3105 28.5793 60.2008 28.3502 60.1285C28.3272 60.1212 28.2818 60.1071 28.2306 60.0957C28.2302 60.0956 28.2297 60.0955 28.2293 60.0954C28.2115 60.0914 28.1396 60.0752 28.0489 60.0711C28.0484 60.071 28.0478 60.071 28.0471 60.071C28.0136 60.0694 27.8717 60.0628 27.7023 60.1157C27.6113 60.1442 27.4345 60.2134 27.274 60.383C27.0872 60.5802 26.9911 60.8419 27.0006 61.1068C27.0088 61.3339 27.0914 61.4989 27.1381 61.5782C27.1887 61.6643 27.2417 61.7249 27.2729 61.7579C27.3346 61.8234 27.3915 61.8659 27.4141 61.8824C27.4651 61.9195 27.5096 61.9445 27.5254 61.9533C27.5649 61.9753 27.6024 61.9932 27.6252 62.0039C27.7205 62.0483 27.8712 62.1103 28.0555 62.1814C28.4336 62.3273 29.0274 62.5399 29.7855 62.7779C31.9404 63.527 35.3978 64.4993 39.995 64.5C39.9958 64.5 39.9967 64.5 39.9975 64.5H39.9975Z" fill="var(--pal-main)" stroke="var(--pal-main)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <circle cx="56.5" cy="43.5" r="7.5" fill="var(--pal-cream)"/>
            <circle cx="23.5" cy="43.5" r="7.5" fill="var(--pal-cream)"/>
        </svg>

    );
}

export function MentorAttention() {
    return(
        <svg className="mentor-face"  width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="41" cy="41" r="41" fill="var(--pal-cream)"/>
            <circle cx="23.5" cy="30.5" r="9.5" fill="var(--pal-main)"/>
            <circle cx="57" cy="31" r="10" fill="var(--pal-main)"/>
            <path d="M23.9343 55.4438C23.5759 55.3535 23.1969 55.4683 22.9488 55.7422C22.7007 56.0162 22.6239 56.4047 22.7492 56.7524C23.7348 59.4882 27.5457 65.2405 35.115 67.2686C42.692 69.2989 49.5104 66.092 52.0255 64.2011C52.3335 63.9696 52.481 63.5818 52.4049 63.2042C52.3288 62.8265 52.0425 62.5262 51.6689 62.4321L23.9343 55.4438Z" fill="var(--pal-main)" stroke="var(--pal-main)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <ellipse cx="57" cy="38.5" rx="10" ry="7.5" fill="var(--pal-cream)"/>
            <ellipse cx="24" cy="38.5" rx="10" ry="7.5" fill="var(--pal-cream)"/>
        </svg>
    );
}

export function MentorHappy() {
    return(
        <svg className="mentor-face"  width="84" height="83" viewBox="0 0 84 83" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="42.0002" cy="41.2145" r="41" transform="rotate(15 42.0002 41.2145)" fill="var(--pal-cream)"/>
            <circle cx="27.8142" cy="26.5428" r="9.5" transform="rotate(15 27.8142 26.5428)" fill="var(--pal-main)"/>
            <circle cx="60.0427" cy="35.6962" r="10" transform="rotate(15 60.0427 35.6962)" fill="var(--pal-main)"/>
            <ellipse cx="58.1019" cy="42.9405" rx="10" ry="7.5" transform="rotate(15 58.1019 42.9405)" fill="var(--pal-cream)"/>
            <path d="M35.467 61.7223C35.8898 61.8378 36.3155 61.942 36.7419 62.0361L36.7438 62.0365C40.1375 62.7988 43.1782 62.9096 45.4384 62.8641C46.5079 62.858 47.401 62.818 48.0292 62.7793C48.3442 62.7599 48.5929 62.7408 48.7644 62.7264C48.8501 62.7192 48.9165 62.7132 48.9623 62.7089L49.0152 62.7038L49.0297 62.7024L49.0339 62.7019L49.0353 62.7018L49.0357 62.7018L49.0359 62.7017C49.036 62.7017 49.0361 62.7017 48.9332 61.707L49.0361 62.7017C49.5677 62.6467 49.9622 62.1833 49.9315 61.6497C49.9009 61.116 49.456 60.7009 48.9215 60.7071C48.627 60.7105 48.2307 60.734 47.78 60.7606C47.6851 60.7662 47.5879 60.772 47.4887 60.7777C46.9035 60.8115 46.2066 60.848 45.4126 60.8642C43.1557 60.8765 40.1125 60.731 37.1783 60.0843C36.7866 59.9962 36.39 59.899 35.9895 59.7917C32.4009 58.8104 29.1007 56.9827 27.1469 55.7439C26.748 55.4726 26.4057 55.2275 26.1118 55.0167C26.1001 55.0084 26.0885 55 26.0769 54.9917C25.8033 54.7956 25.5506 54.6143 25.348 54.4851C25.3277 54.4721 25.2875 54.4468 25.241 54.4225C25.2406 54.4223 25.2402 54.4221 25.2398 54.4219C25.2237 54.4134 25.1584 54.3792 25.0719 54.3517C25.0713 54.3515 25.0708 54.3514 25.0702 54.3512C25.0382 54.341 24.9028 54.2978 24.7255 54.3051C24.6303 54.3091 24.4415 54.3302 24.2426 54.4524C24.0112 54.5946 23.8506 54.8225 23.7912 55.0808C23.7403 55.3023 23.7775 55.483 23.802 55.5718C23.8286 55.6681 23.8641 55.7403 23.8857 55.7802C23.9283 55.8595 23.9723 55.9153 23.9899 55.937C24.0295 55.9861 24.066 56.0217 24.079 56.0343C24.1115 56.0658 24.143 56.0928 24.1623 56.109C24.2429 56.1766 24.3724 56.2755 24.5321 56.3919C24.8595 56.6306 25.378 56.9897 26.0487 57.4157C27.9363 58.6971 31.0242 60.5311 35.4646 61.7216C35.4654 61.7218 35.4662 61.722 35.467 61.7223L35.467 61.7223Z" fill="var(--pal-main)" stroke="var(--pal-main)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <ellipse cx="26.2259" cy="34.3995" rx="10" ry="7.5" transform="rotate(15 26.2259 34.3995)" fill="var(--pal-cream)"/>
        </svg>

    );
}

export function MentorSad() {
    return(
        <svg className="mentor-face" width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="41" cy="41" r="41" fill="var(--pal-cream)"/>
            <circle cx="23.5" cy="30.5" r="9.5" fill="var(--pal-main)"/>
            <circle cx="57" cy="31" r="10" fill="var(--pal-main)"/>
            <path d="M54.1358 61.0695C54.594 60.9445 54.9012 60.5145 54.8707 60.0405C54.8402 59.5666 54.4806 59.1794 54.0101 59.1142C52.9459 58.9666 50.0319 59.0087 45.9976 60.0897C41.9502 61.1742 39.1173 62.7238 38.1398 63.4225C37.7458 63.7041 37.6102 64.2275 37.818 64.665C38.0257 65.1025 38.5171 65.3282 38.9843 65.2008L54.1358 61.0695Z" fill="var(--pal-main)" stroke="var(--pal-main)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <ellipse cx="57" cy="20.5" rx="10" ry="7.5" fill="var(--pal-cream)"/>
            <ellipse cx="24" cy="20.5" rx="10" ry="7.5" fill="var(--pal-cream)"/>
        </svg>
    );
}

export function MentorThink() {
    return(
        <svg className="mentor-face thinking" width="82" height="114" viewBox="0 0 82 114" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M37.2273 19.1506V18.7159C37.2358 17.2244 37.3679 16.0355 37.6236 15.1491C37.8878 14.2628 38.2713 13.5469 38.7741 13.0014C39.277 12.456 39.8821 11.9616 40.5895 11.5185C41.1179 11.1776 41.5909 10.8239 42.0085 10.4574C42.4261 10.0909 42.7585 9.68608 43.0057 9.2429C43.2528 8.79119 43.3764 8.28835 43.3764 7.73438C43.3764 7.14631 43.2358 6.63068 42.9545 6.1875C42.6733 5.74432 42.294 5.40341 41.8168 5.16477C41.348 4.92614 40.8281 4.80682 40.2571 4.80682C39.7031 4.80682 39.179 4.9304 38.6847 5.17756C38.1903 5.41619 37.7855 5.77415 37.4702 6.25142C37.1548 6.72017 36.9844 7.30398 36.9588 8.00284H31.7429C31.7855 6.29829 32.1946 4.89204 32.9702 3.78409C33.7457 2.66761 34.7727 1.83665 36.0511 1.29119C37.3295 0.737215 38.7401 0.460226 40.2827 0.460226C41.9787 0.460226 43.4787 0.741476 44.7827 1.30398C46.0866 1.85795 47.1094 2.66335 47.8509 3.72017C48.5923 4.77699 48.9631 6.05114 48.9631 7.54261C48.9631 8.53977 48.7969 9.42614 48.4645 10.2017C48.1406 10.9687 47.6847 11.6506 47.0966 12.2472C46.5085 12.8352 45.8139 13.3679 45.0128 13.8452C44.3395 14.2457 43.7855 14.6634 43.3509 15.098C42.9247 15.5327 42.6051 16.0355 42.392 16.6065C42.1875 17.1776 42.081 17.8807 42.0724 18.7159V19.1506H37.2273ZM39.7585 27.3324C38.9062 27.3324 38.1776 27.0341 37.5724 26.4375C36.9759 25.8324 36.6818 25.108 36.6903 24.2642C36.6818 23.429 36.9759 22.7131 37.5724 22.1165C38.1776 21.5199 38.9062 21.2216 39.7585 21.2216C40.5682 21.2216 41.2798 21.5199 41.8935 22.1165C42.5071 22.7131 42.8182 23.429 42.8267 24.2642C42.8182 24.8267 42.669 25.3423 42.3793 25.8111C42.098 26.2713 41.7273 26.642 41.267 26.9233C40.8068 27.196 40.304 27.3324 39.7585 27.3324Z" fill="var(--pal-cream)"/>
            <circle cx="41" cy="73" r="41" fill="var(--pal-cream)"/>
            <circle cx="24" cy="62" r="9" fill="var(--pal-main)"/>
            <ellipse cx="56" cy="64" rx="10" ry="11" fill="var(--pal-main)"/>
            <ellipse cx="22" cy="48.5" rx="11" ry="8.5" fill="var(--pal-cream)"/>
            <ellipse cx="56" cy="53.5" rx="10" ry="6.5" fill="var(--pal-cream)"/>
            <path d="M30 89.1538C30.913 88.7692 33.5609 88 36.8478 88C40.1348 88 47.6522 90 51 91" stroke="var(--pal-main)" strokeWidth="3" strokeLinecap="round"/>
        </svg>

    );
}

export function MentorAnswer() {
    return(
        <svg className="mentor-face answering" width="82" height="114" viewBox="0 0 82 114" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="41" cy="73" r="41" fill="var(--pal-cream)"/>
            <circle cx="23.5" cy="62.5" r="9.5" fill="var(--pal-main)"/>
            <circle cx="57" cy="63" r="10" fill="var(--pal-main)"/>
            <path d="M23.9343 87.4438C23.5759 87.3535 23.1969 87.4683 22.9488 87.7422C22.7007 88.0162 22.6239 88.4047 22.7492 88.7524C23.7348 91.4882 27.5457 97.2405 35.115 99.2686C42.692 101.299 49.5104 98.092 52.0255 96.2011C52.3335 95.9696 52.481 95.5818 52.4049 95.2042C52.3288 94.8265 52.0425 94.5262 51.6689 94.4321L23.9343 87.4438Z" fill="var(--pal-main)" stroke="var(--pal-main)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <ellipse cx="57" cy="70.5" rx="10" ry="7.5" fill="var(--pal-cream)"/>
            <ellipse cx="24" cy="70.5" rx="10" ry="7.5" fill="var(--pal-cream)"/>
            <path d="M43.3445 0.818181L42.8459 19.1506H38.1669L37.6555 0.818181H43.3445ZM40.5064 27.3324C39.6626 27.3324 38.9382 27.0341 38.3331 26.4375C37.728 25.8324 37.4297 25.108 37.4382 24.2642C37.4297 23.429 37.728 22.7131 38.3331 22.1165C38.9382 21.5199 39.6626 21.2216 40.5064 21.2216C41.3161 21.2216 42.0277 21.5199 42.6413 22.1165C43.255 22.7131 43.5661 23.429 43.5746 24.2642C43.5661 24.8267 43.4169 25.3423 43.1271 25.8111C42.8459 26.2713 42.4751 26.642 42.0149 26.9233C41.5547 27.196 41.0518 27.3324 40.5064 27.3324Z" fill="var(--pal-cream)"/>
        </svg>
    );
}